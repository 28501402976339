
const quizs = [
    {
      question: 'Lần cuối bạn mua đồ là',
      category: 'frequency',
      answers: [
        {
          text: 'Hôm qua',
          value: 4
        },
        {
          text: 'Tuần trước',
          value: 3
        },
        {
          text: 'Tháng trước',
          value: 2
        },
        {
          text: 'Không nhớ',
          value: 1
        }
      ]
    },
    {
      question: 'Trung bình mỗi lần mua sắm quần áo bạn thường tiêu hết',
      category: 'avg_price',
      answers: [
        {
          text: 'Dưới 600k',
          value: 500
        },
        {
          text: '600k - 1 triệu 500',
          value: 1050
        },
        {
          text: '1 triệu 500 - 2 triệu 500',
          value: 2000
        },
        {
          text: 'Trên 2 triệu 500',
          value: 3500
        }
      ]
    },
    {
      question: 'Bạn thường mua đồ mới',
      category: 'frequency',
      answers: [
        {
          text: 'Một tháng một lần',
          value: 8
        },
        {
          text: 'Hai tháng một lần',
          value: 4
        },
        {
          text: 'Hai tuần một lần',
          value: 12
        },
        {
          text: 'Tuần nào cũng mua',
          value: 16
        }
      ]
    },
    {
      question: 'Bạn hiện có ____ đồ trong tủ',
      category: 'frequency',
      answers: [
        {
          text: 'Dưới 20 món',
          value: 1
        },
        {
          text: '20 - 40 món',
          value: 2
        },
        {
          text: '40 - 80 món',
          value: 3
        },
        {
          text: 'Nhiều quá đếm không hết',
          value: 4
        }
      ]
    },
    {
      question: 'Bạn thường mua đồ vì',
      category: 'wasted',
      id: 1,
      answers: [
        {
          text: 'Cần nên mua',
          value: 1
        },
        {
          text: 'Có sales nên mua',
          value: 4
        },
        {
          text: 'Thấy đồ mới nên mua',
          value: 3
        },
        {
          text: 'Thích thì mua, không cần lý do',
          value: 2
        }
      ]
    },
    {
      question: 'Khi mua đồ mới, điều bạn quan tâm nhất là',
      category: 'wasted',
      id: 2,
      answers: [
        {
          text: 'Giá',
          value: 4
        },
        {
          text: 'Thương hiệu',
          value: 3
        },
        {
          text: 'Đẹp là được',
          value: 2
        },
        {
          text: 'Phù hợp với các món đồ đã có',
          value: 1
        }
      ]
    },
    {
      question: 'Bạn tự đánh giá mình sử dụng bao % của tủ đồ',
      category: 'wasted',
      id: 3,
      answers: [
        {
          text: 'Toàn bộ',
          value: 1
        },
        {
          text: 'Khoảng 10-20 %',
          value: 4
        },
        {
          text: 'Khoảng 20-40 %',
          value: 3
        },
        {
          text: 'Khoảng 40-70 %',
          value: 2
        }
      ]
    },
    {
      question: 'Trong tủ đồ của bạn có bao nhiêu món đồ chưa mặc lần nào ?',
      category: 'wasted',
      id: 4,
      answers: [
        {
          text: 'Không có món nào',
          value: 1
        },
        {
          text: 'Dưới 5 món',
          value: 2
        },
        {
          text: 'Từ 5 - 10 món',
          value: 3
        },
        {
          text: 'Trên 10 món',
          value: 4
        }
      ]
    },
    {
      question: 'Có bao giờ bạn thấy "Không có gì để mặc" ?',
      category: 'wasted',
      id: 5,
      answers: [
        {
          text: 'Chưa bao giờ',
          value: 1
        },
        {
          text: 'Thỉnh thoảng',
          value: 2
        },
        {
          text: 'Ngày nào cũng vậy',
          value: 4
        }
      ]
    }
  ]

  export default quizs;