import React, {useState} from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';


import Footer from '../components/Footer';
import ScrollToTop from '../components/ScrollToTop';

import { Wizard, Steps, Step, WithWizard } from 'react-albus';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Line } from 'rc-progress';
import quizs from '../quiz';
import withLocation from '../withLocation';

const caculator = (answers) => {
  const result = Object.keys(answers).map((key) => answers[key]);
  const avgPrice = result.find((answer) => answer.category === 'avg_price').value;
  const percentRandomize = Math.floor(Math.random() * 20)/100;
  const usedPrice = avgPrice * (1 + percentRandomize);
  const frequencyPoint = result.filter((answer) => answer.category === 'frequency').reduce((total, answer) => total + answer.value, 0);
  const totalFrequencyPoint = quizs.filter((quiz) => quiz.category === 'frequency').length * 4 + 12;
  const usedFrequency = 30 * frequencyPoint/totalFrequencyPoint;
  const total = Math.floor(usedPrice * usedFrequency);
  const wastedPoint = result.filter((answer) => answer.category === 'wasted').reduce((total, answer) => total + answer.value, 0);
  const totalWastedPoint = quizs.filter((quiz) => quiz.category === 'wasted').length * 4;
  const usedWasted = 95 * wastedPoint/totalWastedPoint/100;
  const wasted = Math.floor(total*usedWasted);
  const topWasted = result.filter((answer) => answer.category === 'wasted' && answer.value > 2).map((answer) => answer.id);
  const encrypted = Buffer.from((`${wasted};${total};${topWasted.join(';')}`)).toString('base64');
  return encrypted;
}

const IndexPage = ({navigate}) => {
  const [answers, setAnswers] = useState({});
  const complete = () => {
    const id = caculator(answers);
    navigate(`/result?id=${id}`, { replace: true })
  }
  return (
    <Layout>
      <header className="masthead d-flex">
        <div className="container text-center my-auto">
          <h1 className="mb-1">Bạn đã chi</h1>
          <h3 className="mb-5">
            bao nhiêu tiền cho quần áo ?
          </h3>
          <Scroll type="id" element="quiz" offset={100}>
            <a className="btn btn-primary btn-xl" href="#quiz">
              Bắt Đầu
            </a>
          </Scroll>
        </div>
        <div className="overlay"></div>
      </header>
      <section className="content-section bg-light" id="quiz">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <h2>
                Hãy lần lượt trả lời (trung thực) các câu hỏi sau
              </h2>
              <p className="lead mb-5">
                Hệ thống "trí tuệ nhân tạo" sẽ giúp bạn tính toán chi phí bạn đã bỏ ra cho quần áo
              </p>
              <Wizard
                render={({ step, steps }) => (
                  <div>
                    <Line
                      percent={(steps.indexOf(step) + 1) / steps.length * 100}
                      strokeColor='#63c4e3'
                      className='quiz-progress'
                    />
                    <TransitionGroup>
                      <CSSTransition
                        key={step.id}
                        classNames="quiz"
                        timeout={{ enter: 500, exit: 500 }}
                      >
                        <div className="quiz-steps container-fluid">
                          <Steps key={step.id} step={step}>
                            {quizs.map((quiz, index) => (
                            <Step id={`${index}`}>
                              <div>
                                <h2 className="text-align-center">{quiz.question}</h2>
                                {quiz.answers.map((answer) => (
                                  <div className="form-check answer">
                                    <input class="form-check-input" type="radio" name={quiz.question} id={answer.text} value={answer.value}
                                      onChange={() => {
                                        answers[quiz.question] = {
                                          id: quiz.id,
                                          value: answer.value,
                                          category: quiz.category
                                        };
                                        setAnswers({...answers});
                                      }}
                                      checked={answers[quiz.question] && answers[quiz.question].value === answer.value}
                                    />
                                    <label class="form-check-label" for={answer.text}>
                                      {answer.text}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </Step>)
                            )}
                          </Steps>
                        </div>
                      </CSSTransition>
                      <WithWizard
                        render={({ next, previous, step, steps }) => (
                          <div className="quiz-buttons container-fluid">
                            {steps.indexOf(step) < steps.length - 1 && (
                              <button className="btn btn-primary btn-lg mr-4" disabled={Object.keys(answers).length - 1 < steps.indexOf(step)} onClick={next}>
                                Next
                              </button>
                            )}

                            {steps.indexOf(step) === steps.length - 1 && (
                              <button className="btn btn-primary btn-lg mr-4" onClick={complete}>
                                Hoàn thành
                              </button>
                            )}
  
                            {steps.indexOf(step) > 0 && (
                              <button className="btn btn-secondary btn-lg" onClick={previous}>
                                Back
                              </button>
                            )}
                          </div>
                        )}
                      />
                    </TransitionGroup>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </section>
      <ScrollToTop />
      <Footer />
    </Layout>
  );
}

export default withLocation(IndexPage);
